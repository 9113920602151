<template>
  <div class="page-stocks">
    <div class="container">
      <div class="page-stocks__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link page-stocks-link"> {{ $t("stock") }}</a>
      </div>
      <div class="page-stocks__header">
        <h1 class="title">{{ $t("stock") }}</h1>
        <div class="page-stocks__tabs">
          <div
            v-for="st in stocksType"
            :key="st.id"
            class="page-stocks__tab"
            :class="{ 'is-active': st.id === selectedStockType.id }"
            @click="selectStockByStatus(st)"
          >
            {{ st.label }}
          </div>
        </div>
      </div>
      <div v-if="stocks" class="page-stocks__items">
        <locale-router-link
          v-for="item in stocks.data"
          :key="item.id"
          :to="getComponent(item)"
          class="stocks__item"
          ><img
            :src="getImage(item)"
            alt="stock image"
            width="380"
            height="320"
        /></locale-router-link>
      </div>
      <div
        v-if="!stocks.data.length && selectStock.type === 'announcement'"
        class="page-stocks__announcement"
      >
        <p class="page-stocks__announcement-text">
          {{ $t("announced") }}
        </p>
        <img
          class="page-stocks__announcement-img"
          src="@/assets/img/stocks/popcorn.svg"
          alt=""
        />
      </div>
      <button
        v-if="!pagination.isLastPage && selectedStockType.id !== 2"
        class="page-stocks-more"
        @click="nextStocksPage"
        :disabled="isLoading"
      >
        {{ $t("seeAll") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/stock";
export default {
  name: "PageStocks",
  data() {
    return {
      stocksType: [
        { id: 1, label: this.$t("current"), type: "current", isVisible: true },
        {
          id: 2,
          label: this.$t("announcement"),
          type: "announcement",
          isVisible: true,
        },
        {
          id: 3,
          label: this.$t("completed"),
          type: "completed",
          isVisible: true,
        },
      ],
      selectStock: null,
      pagination: {
        page: 1,
        pageSize: 9,
        isLastPage: false,
      },
    };
  },
  watch: {
    selectedStockType: {
      handler() {
        this.getSotckByPaginate();
      },
    },

    selectedCity: {
      deep: true,
      handler() {
        this.getSotckByPaginate();
      },
    },
  },
  created() {
    if (!this.$isMobile()) {
      this.pagination.page = 1;
      this.pagination.pageSize = 9;
    } else {
      this.pagination.page = 1;
      this.pagination.pageSize = 5;
    }
    this.changeStockStatus(this.stocksType[0]);
  },
  computed: {
    ...mapState("stockModule", {
      stocks: "data",
      isLoading: "isLoading",
      selectedStockType: "stockStatus",
    }),
    ...mapState("cityModule", {
      selectedCity: "selectedCity",
    }),
  },

  methods: {
    ...mapActions("stockModule", {
      getAllStocks: actionTypes.loadAllStocks,
      changeStockStatus: actionTypes.changeStockStatus,
    }),
    getSotckByPaginate() {
      if (!this.$isMobile()) {
        this.pagination.page = 1;
        this.pagination.pageSize = 9;
      } else {
        this.pagination.page = 1;
        this.pagination.pageSize = 5;
      }

      this.getAllStocks({
        pagination: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        },
        type: "replace",
      }).then((stocks) => {
        if (this.pagination.page === stocks.meta.pagination.pageCount) {
          this.pagination.isLastPage = true;
        } else {
          this.pagination.isLastPage = false;
        }
      });
    },
    selectStockByStatus(st) {
      this.changeStockStatus(st);
      this.selectStock = st;
    },
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.preview_desktop?.data?.attributes?.url
      );
    },
    getComponent(item) {
      if (item.attributes.promo_type === "only_discount") {
        return "stocks/products/" + item.id;
      } else {
        return "stocks/" + item.id;
      }
    },
    nextStocksPage() {
      this.pagination.page++;
      this.getAllStocks({
        pagination: {
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        },
        type: "push",
      }).then((stocks) => {
        if (this.pagination.page === stocks.meta.pagination.pageCount) {
          this.pagination.isLastPage = true;
        } else {
          this.pagination.isLastPage = false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
